function piula(text=document.title, hash='edu365') {

  const url = encodeURIComponent(window.location.href);
  const title=encodeURIComponent(text);
  return `https://twitter.com/intent/tweet?text=${title}&url=${url}&hashtags=${hash}&via=edu365`;
  
}

// --------------------------------------------------

var checkMenuEnabled = { state: false }
function enableMenu(checkMenuEnabled) {
  
  console.log("INTENTANDO CARGAR MENÚ");
  console.log(checkMenuEnabled)
  
  if ($('.edu-menu-1.dropdown').length === 0
      || $('.dropdown-submenu').length === 0
      || $('.edu-menu-1.dropdown > a.edu-navbar-toggle').length === 0
      || $('.dropdown-submenu > a.edu-navbar-toggle').length === 0) 
      return;
  
  
  $('.edu-menu-1.dropdown').hover(
    function() {
      let isSmallScreen = $('#burger').css('display') == 'none'
      if (isSmallScreen)
        $(this).addClass('open');    
    }, function() {
      let isSmallScreen = $('#burger').css('display') == 'none'
      if (isSmallScreen)
        $(this).removeClass('open');    
    }
  );
  
  $('.edu-menu-1.dropdown').focusout(
    function(event) {      
      if (!event.currentTarget.contains(event.relatedTarget))
        $(this).removeClass('open');      
    }
  );
  
  $('.edu-menu-1.dropdown .dropdown-submenu').focusout(
    function(event) {      
      if (!event.currentTarget.contains(event.relatedTarget))
        $(this).removeClass('open');      
    }
  );
  

  $('.dropdown-submenu').hover(
    function() {    
      let isSmallScreen = $('#burger').css('display') == 'none'
      if (isSmallScreen)
        $(this).addClass('open');  
    }, function() { 
      let isSmallScreen = $('#burger').css('display') == 'none'
      if (isSmallScreen)
        $(this).removeClass('open');    
    }
  );

  //$(".edu-menu-1.dropdown > a").click(function(e) {
//    event.preventDefault();
//    event.stopPropagation();  
//    $(this).parent().toggleClass('open');
//  });
//
//  $(".dropdown-submenu > a").click(function(e) {
//    event.preventDefault();
//    event.stopPropagation();
//    $(this).parent().toggleClass('open');
//  });
  
  $(".edu-menu-1.dropdown > a.edu-navbar-toggle").click(function(e) {
    console.log('Click');
    event.preventDefault();
    event.stopPropagation();  
    $(this).parent().toggleClass('open');
  });

  $(".dropdown-submenu > a.edu-navbar-toggle").click(function(e) {
    event.preventDefault();
    event.stopPropagation();
    $(this).parent().toggleClass('open');
  });
  
  console.log("MENÚ CARGADO");
  clearInterval(checkMenuEnabled.state)
  return;
};

checkMenuEnabled.state = setInterval(enableMenu, 500, checkMenuEnabled);

// ---------------------------------------------------------